
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  data() {
    return {
      //加载
      loading: false,
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: -1
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: [],
      //查询类型
      type: "00"
    };
  },
  methods: {
    handleDetail(item) {
      const params = {
        row: JSON.stringify(item), //tb_class_view
        path: this.$route.path
      };
      store.dispatch(Actions.SET_PARAMS_ACTION, params);
      this.$router.push({
        path: "/class/manage"
      });
    },
    handleSetType(type) {
      this.type = type;
      this.loadData();
    },
    loadData() {
      const data = {
        prefix: {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          searchKey: this.searchKey,
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        type: this.type
      };
      this.loading = true;
      ApiService.post("MemberClass/Class", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      this.loadData();
    },
    handleSizeChange(event) {
      this.pagination.perPage = parseInt(event.target.value);
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.loadData();
    }
  },
  mounted() {
    setCurrentPageTitle("班级");
    if (store.getters.isUserAuthenticated) {
      this.loadData();
    }
  }
});
